<style lang="scss">
h1 {
    font-weight: 200;
}
.answer {
    font-weight: 900;
}
</style>
<template>
    <div id="mpReport">

        <div class="row" v-if="this.errors">
            <div class="col-sm-12 mb-2 p-2 bg-danger rounded" v-for="error in this.errors">
                <span class="text-white">{{error}}</span>
            </div>
        </div>

        <div class="row spoiler mb-3" v-if="this.attention_title_price">
            <div class="spoiler__title h5">{{attention_title_price}}</div>
            <div class="spoiler__content">
                <div class="col-sm-12 mb-2 p-2 bg-warning rounded" v-for="attentionPrice in this.attention_price">
                    <span class="text-white">{{attentionPrice}}</span>
                </div>
            </div>
        </div>

        <div class="row spoiler mb-3" v-if="this.attention_title_sku">
            <div class="spoiler__title h5">{{this.attention_title_sku}}</div>
            <div class="spoiler__content">
                <div class="col-sm-12 mb-2 p-2 bg-warning rounded" v-for="attentionSku in this.attention_sku">
                    <span class="text-white">{{attentionSku}}</span>
                </div>
            </div>
        </div>

        <ReportForm/>
    </div>
</template>

<script>
//import Vue from "vue";
import {mapState, mapActions} from 'vuex';
import ReportForm from './components/ReportForm'

export default {
    name: "MpReport",
    components: {ReportForm},
    computed:{
      ...mapState(['loading', 'errors', 'attention_title_price', 'attention_price', 'attention_title_sku', 'attention_sku']),
      // ...mapGetters([]),
    },
    mounted() {
        this.initialLoad();
    },
    methods: {
        ...mapActions(['initialLoad'])
    },
    data() {
        return {
            testAttr: true,
        }
    },
};
</script>