import Vue from 'vue'
import MpReport from './MpReport.vue'
import store from './reportStore'

new Vue({
    el: '#mpReport',
    store,
    components: {
        MpReport
    },
    template: '<MpReport/>',
})
