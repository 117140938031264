import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.prototype.axios = axios;
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		loading: true,
		test: 1,
		errors: [],
		attention_title_price: '',
		attention_price: [],
		attention_title_sku: '',
		attention_sku: [],
		form: {
			tax_num: 6,
		}
	},
	actions: {
		//{dispatch, state, commit}
		/*
		axios.post("/index.php?route=checkout/api/updateUserData", payload.key + '=' + payload.value).then(() => {
					commit('LOADING', false)
				}).catch(error => {
					throw new Error(`API ${error}`);
				})
		 */
		initialLoad({commit, state}) {
			commit('LOADING', false)
			return true
		},
		updateTaxNum({commit}, data) {
			commit('LOADING', true)
			let tax = data.e.target.value
			commit('UPDATE_TAXNUM', tax)
			commit('LOADING', false)
			return true

		},
		uploadReportFile({commit}, data) {
			commit('LOADING', true)
			return axios.post("/StatisticFromMp/uploadReportFile", data.formData, {headers: {'Content-Type': 'multipart/form-data'}})
				.then(result => {
					if (result.data.errors) {
						commit('UPDATE_ERRORS', result.data.errors)
					}
					console.log(result);
				}).catch(error => {
					throw new Error(`API ${error}`);
				});
		}
	},
	getters: {
		test: state => {
			return state.test === 1;
		}
	},
	mutations: {
		LOADING(state, status) {
			state.loading = status
		},
		UPDATE_TAXNUM(state, value) {
			state.form.tax_num = value
		},
		UPDATE_ERRORS(state, data) {
			state.errors = data
		}
	}
});
