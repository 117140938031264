<style lang="scss">
#reportForm {
    h1 {
        font-weight: 100;
    }
}
</style>
<template>
    <div id="reportForm">
        <form action="" method="POST" enctype="multipart/form-data">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-2 mb-3">
                            <label for="input-name-ozon" class="col-form-label">
                                <input id="input-name-ozon" type="radio" value="ozon" class="me-1" v-model="marketplaceName" checked>
                                Ozon
                            </label>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="input-name-wb" class="col-form-label">
                                <input id="input-name-wb" type="radio" value="wildberries" class="me-1" v-model="marketplaceName" disabled>
                                Wildberries
                            </label>
                        </div>
                        <div class="col-sm-6 mb-2 mt-2 p-2 bg-danger rounded" v-if="errorMarketplaceName">
                            <span class="text-white">{{errorMarketplaceName}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <input type="file" @change="handleFileUpload('reportFile', 'statistic_file')" class="form-control" id="input-file" ref="statistic_file" >
                        </div>
                        <div class="col-sm-6">
                            <button type="submit" class="btn btn-primary" @click.prevent="parseFile()">Разобрать</button>
                        </div>
                        <div class="col-sm-6 mb-2 mt-2 p-2 bg-danger rounded" v-if="errorReportFile">
                            <span class="text-white">{{errorReportFile}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 mb-4">
                    <div class="row">
                        <div class="col-sm-12 mb-3">
                            <label class="col-form-label">
                                <span>Выберите файл для загрузки информации о товарах (опционально)</span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6"><input type="file" @change="handleFileUpload('purchaseFile', 'purchase_price_file')" class="form-control" id="input-purchase-file" ref="purchase_price_file" ></div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="col-form-label-sm">
                        <label for="input-calculate_tax" class="col-form-label me-2">
                            <input type="checkbox" id="input-calculate_tax"  value="1" :checked="calculateTaxChecked" v-model="calculateTaxChecked">
                            <span>Считать налог</span>
                        </label>
                        <input type="text" class="col-form-label-sm" :value="this.form.tax_num" @change="updateTaxNum({e: $event})">
                    </div>
                    <div class="col-sm-6 mb-2 mt-2 p-2 bg-danger rounded" v-if="errorCalculateTax">
                        <span class="text-white">{{errorCalculateTax}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
// import Vue from "vue";
import {mapState, mapActions} from 'vuex';

export default {
    name: "ReportForm",
    computed: {
        ...mapState(['form'])
    },
    methods: {
        ...mapActions(['updateTaxNum']),
        handleFileUpload(name, ref) {
            this[name] = this.$refs[ref].files[0]
        },
        parseFile() {
            let formData = new FormData();
            formData.append('marketplaceName', this.marketplaceName);
            formData.append('reportFile', this.reportFile);
            formData.append('purchaseFile', this.purchaseFile);
            if (this.calculateTaxChecked) {
                formData.append('tax_num', this.form.tax_num);
            }
            this.$store.dispatch('uploadReportFile', {formData});
        }
    },
    data() {
        return {
            errorReportFile: '',
            errorCalculateTax: '',
            errorMarketplaceName: '',
            calculateTaxChecked: false,
            marketplaceName: '',
            reportFile: {},
            purchaseFile : {},
        }
    },
};
</script>